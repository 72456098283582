var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-navbar",
    { attrs: { toggleable: "lg", type: "light", variant: "light" } },
    [
      _c("b-navbar-brand", { attrs: { href: "/" } }, [
        _c("img", {
          attrs: {
            src: require("../assets/brand.svg"),
            height: "64",
            alt: "logo"
          }
        })
      ]),
      _vm._v(" "),
      _c("b-navbar-toggle", { attrs: { target: "nav-collapse" } }),
      _vm._v(" "),
      _c(
        "b-collapse",
        { attrs: { id: "nav-collapse", "is-nav": "" } },
        [
          _c(
            "b-navbar-nav",
            { staticClass: "ml-auto align-middle" },
            [
              _c("b-nav-item", { staticClass: "mr-5", attrs: { to: "/" } }, [
                _c("h1", [_vm._v("Home")])
              ]),
              _vm._v(" "),
              _c(
                "b-nav-item",
                { staticClass: "mr-5", attrs: { to: "/profile" } },
                [_c("h1", [_vm._v("Profile")])]
              ),
              _vm._v(" "),
              _c(
                "b-nav-item",
                { staticClass: "mr-5", attrs: { to: "/skill" } },
                [_c("h1", [_vm._v("Skill")])]
              ),
              _vm._v(" "),
              _c(
                "b-nav-item",
                { staticClass: "mr-5", attrs: { to: "/work" } },
                [_c("h1", [_vm._v("Work")])]
              ),
              _vm._v(" "),
              _c(
                "b-nav-item",
                { staticClass: "mr-5", attrs: { to: "/contact" } },
                [_c("h1", [_vm._v("Contact")])]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }