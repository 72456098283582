<template>
  <div class="work">
    <b-jumbotron text-variant="dark">
      <template slot="header">
        Work
        <i class="fas fa-business-time"></i>
      </template>
    </b-jumbotron>
    <div class="row">
      <div class="col-sm-6 mb-3">
        <b-card img-src="../assets/brand.svg" img-alt="Rorange Portfolio" img-top>
          <b-card-text>
            <h2>Rorange Portfolio</h2>このサイトです．
          </b-card-text>
        </b-card>
      </div>
      <div class="w-100"></div>
    </div>
  </div>
</template>

<script>
export default {};
</script>
