var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "work" },
    [
      _c(
        "b-jumbotron",
        { attrs: { "text-variant": "dark" } },
        [
          _c("template", { slot: "header" }, [
            _vm._v("\n      Work\n      "),
            _c("i", { staticClass: "fas fa-business-time" })
          ])
        ],
        2
      ),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-sm-6 mb-3" },
          [
            _c(
              "b-card",
              {
                attrs: {
                  "img-src": "../assets/brand.svg",
                  "img-alt": "Rorange Portfolio",
                  "img-top": ""
                }
              },
              [
                _c("b-card-text", [
                  _c("h2", [_vm._v("Rorange Portfolio")]),
                  _vm._v("このサイトです．\n        ")
                ])
              ],
              1
            )
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "w-100" })
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }